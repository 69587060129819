import * as React from 'react';
import {Helmet} from 'react-helmet';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

import Layout from '@components/layouts/layout';
import Navigation from '@components/nav-footer/navigation';
import Footer from '@components/nav-footer/footer';
import ContactSection from '@components/common/contact-section';

import {Container, Row, Col, Image} from 'react-bootstrap';

import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper} from 'simple-react-lightbox';

const ProjectSinglePage = ({pageContext}) => {
  const project = pageContext.project;

  const formatNumber = (number, toFixed = 0, isSquareMeters = false) => {
    const parsedNumber = number
      .toFixed(toFixed)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      .replace(/\./g, ',');

    return !isSquareMeters ? (
      parsedNumber
    ) : (
      <>
        {parsedNumber} m<sup>2</sup>
      </>
    );
  };

  const galleryOptions = {
    settings: {
      overlayColor: 'rgb(255, 255, 255)',
      autoplaySpeed: 10000,
    },
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showFullscreenButton: false,
      showDownloadButton: false,
    },
  };

  return (
    <Layout>
      <Helmet>
        <title>{project.title} – Atakama Studio – Pracownia architektury</title>
        <meta name="description" content={project.excerpt} />
        <meta property="og:title" content={`${project.title} – Atakama Studio – Pracownia architektury`} />
        <meta property="og:description" content={project.excerpt} />
        {project.images && <meta property="og:image" content={`https:${project.images[0].url}`} />}
      </Helmet>

      <Navigation />

      {project.images && (
        <div className="projects-single__hero">
          <div
            className="projects-single__hero__img"
            style={{backgroundImage: `url("${project.images[0].url}")`}}
          ></div>
        </div>
      )}

      <SimpleReactLightbox>
        <div className="projects-single__content">
          <Container>
            <Row>
              <Col>
                <h1>{project.title}</h1>
              </Col>
            </Row>
            <Row className="projects-single__content__data">
              {project.year && (
                <Row>
                  <span>Rok projektu:</span>
                  <span>{project.year}</span>
                </Row>
              )}
              {project.numberOfFloors && (
                <Row>
                  <span>Liczba kondygnacji:</span>
                  <span>{project.numberOfFloors}</span>
                </Row>
              )}
              {project.buildingArea && (
                <Row>
                  <span>Powierzchnia zabudowy:</span>
                  <span>{formatNumber(project.buildingArea, 2, true)}</span>
                </Row>
              )}
              {project.usableArea && (
                <Row>
                  <span>Powierzchnia użytkowa:</span>
                  <span>{formatNumber(project.usableArea, 2, true)}</span>
                </Row>
              )}
              {project.plotArea && (
                <Row>
                  <span>Powierzchnia działki:</span>
                  <span>{formatNumber(project.plotArea, 2, true)}</span>
                </Row>
              )}
              {project.garage === true && (
                <Row>
                  <span>Garaż podziemny:</span>
                  <span>Tak</span>
                </Row>
              )}
            </Row>
            <Row className="projects-single__content__text">
              <Col md={8}>{documentToReactComponents(project.desc)}</Col>
            </Row>
            <Row>
              <Col md={8} className="projects-single__content__images">
                <SRLWrapper options={galleryOptions}>
                  {project.images &&
                    project.images.map(image => {
                      return <Image fluid src={image.url} alt="Obrazek" srl_gallery_image="true" />;
                    })}
                </SRLWrapper>
              </Col>
            </Row>
          </Container>
        </div>
      </SimpleReactLightbox>

      <ContactSection />

      <Footer />
    </Layout>
  );
};

export default ProjectSinglePage;
