import * as React from 'react';
import {Link} from 'gatsby';

import {Container, Row, Col} from 'react-bootstrap';

import {Instagram, Facebook, Envelope, Telephone} from 'react-bootstrap-icons';

const ContactSection = props => {
  return (
    <div className="common-contact" ref={props.refProp}>
      <Container>
        <Row>
          <Col>
            <h2>Kontakt</h2>

            <p>
              <strong>Atakama Studio</strong>
              <br />
              arch. Karolina König
            </p>
            <p className="mb-3">
              <Envelope /> kontakt@atakama.studio
              <br />
              <Telephone /> 536 171 518
            </p>
            {/* <p className="common-contact__social-icons mb-5">
              <a href="https://www.facebook.com/atakama.studio.arch" className="social-icon">
                <Facebook />
              </a>
              <a href="https://www.instagram.com/atakama.studio.arch" className="social-icon">
                <Instagram />
              </a>
            </p> */}
            <Link className="common-contact__contact-link" to={`/kontakt`}>
              Formularz kontaktowy
            </Link>
            <Link className="common-contact__privacy-policy-link" to={`/polityka-prywatnosci`}>
              Polityka prywatności
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
